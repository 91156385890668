<template>
  <resize-observer @resize="recomputeHeight">
    <div
      ref="wrapper"
      class="wrapper"
    >
      <v-menu
        :disabled="disabled || readonly"
        transition="scale-transition"
        offset-y
        max-width="700"
        :close-on-content-click="false"
        :close-on-click="false"
        v-model="modal"
        :attach="computedAttach"
        :top="isOutOfView"
        :origin="origin || (isOutOfView ? 'bottom left' : 'top left')"
        eager
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :solo="solo"
            :flat="flat"
            :label="label"
            :value="computedDateResolved"
            :clearable="clearable"
            v-on="on"
            ref="text"
            @focus="blur"
            @click:clear="clearDate"
            required
            :rules="computedRules"
            :disabled="disabled"
            :prepend-inner-icon="prependIconComputed"
            @hook:mounted="validate"
          />
        </template>
        <v-card class="ma-0">
          <v-tabs
            v-model="tab"
            centered
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab
              href="#tab1"
              @click="tab1Click"
            >
              <v-icon left>
                {{$icon('i.Calendar')}}
              </v-icon>
              {{$t('t.Calendar')}}
            </v-tab>
            <v-tab
              href="#tab2"
              @click="tab2Click"
            >
              <v-icon left>
                {{$icon('i.CalendarSliding')}}
              </v-icon>
              {{$t('t.CalendarSliding')}}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab1">
              <v-date-picker
                class="picker-date"
                :first-day-of-week="firstDayOfWeek"
                :locale="i18n.locale"
                :locale-first-day-of-year="4"
                :min="min"
                :max="max"
                no-title
                scrollable
                show-week
                v-if="!isSlidingDate"
                v-model="date"
              />
            </v-tab-item>
            <v-tab-item value="tab2">
              <div class="d-flex mt-5">
                <v-select
                  class="mt-5 mx-4 select"
                  :items="dateSeeksList"
                  item-text="value"
                  item-value="key"
                  :label="$t('t.DateSeek')"
                  v-model="dateSeek"
                >
                </v-select>
                <v-select
                  v-if="dateSeek === 'month-start' || dateSeek === 'month-end'"
                  class="mt-5 mr-4 select"
                  :items="monthsList"
                  item-text="value"
                  item-value="key"
                  :label="$t('t.CurrentDate')"
                  v-model="month"
                  clearable
                >
                </v-select>
                <v-text-field
                  class="mt-5 mr-4 inputnumber"
                  type="number"
                  v-if="dateSeek == 'week'"
                  v-model="week"
                  :label="$t('t.WeekNumber')"
                  :required="dateSeek === 'week'"
                />
                <v-select
                  v-if="!selectedPeriod"
                  class="mt-5 mr-5 select"
                  :items="daysList"
                  item-text="value"
                  item-value="key"
                  :label="dayText"
                  v-model="slidingWeekday"
                  :clearable="!isWeekSeek"
                >
                </v-select>
              </div>
              <div class="mt-5 d-flex">
                <v-chip
                  class="ml-5 resolveChip"
                  outlined
                  text-color="primary"
                  large
                >
                  {{computedDateResolved}}
                </v-chip>
                <v-text-field
                  class="ml-5 inputnumber"
                  type="number"
                  required
                  v-model="days"
                  :label="$t('t.AddDays')"
                />
                <v-text-field
                  class="ml-2 mr-2 inputnumber"
                  type="number"
                  v-if="!month"
                  v-model="months"
                  required
                  :label="$t('t.AddMonths')"
                />
                <v-menu
                  v-if="!isWeekSeek && !month"
                  bottom
                  offset-y
                  content-class="menu"
                  transition="scale-transition"
                >
                  <template v-slot:activator="{ on }">
                    <div class="d-flex">
                      <v-icon
                        v-on="on"
                        color="primary"
                        class="mr-5"
                      >{{$icon('i.MagicAction')}}</v-icon>
                      <!-- <span class="text--primary mt-6 ml-1 mr-5">{{stepText}}</span> -->
                    </div>
                  </template>
                  <v-card class="d-flex flex-row">
                    <div class="d-flex flex-column">
                      <v-list class="flex-grow-1">
                        <v-list-item
                          v-for="item in stepsList"
                          :key="item.key"
                          @click="changeStep(item)"
                          link
                        >
                          {{item.value}}
                        </v-list-item>
                      </v-list>
                    </div>
                  </v-card>
                </v-menu>
              </div>
            </v-tab-item>
          </v-tabs-items>
          <div class="d-flex">
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="cancel"
            >
              {{$t('t.Cancel')}}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="saveDate"
              :disabled="!canValidate"
            >
              OK
            </v-btn>
          </div>
        </v-card>

      </v-menu>

    </div>
  </resize-observer>
</template>

<script>
import i18n from '../../../plugins/i18n'

export default {
  components: {
    ResizeObserver: () => import('@/components/resize-observer')
  },
  mounted () {
    if (this.date) {
      this.initTab()
      this.resolveDate(this.date)
    }
  },
  computed: {
    canValidate () {
      return !this.isSlidingDate || (!isNaN(this.days) && !isNaN(this.months) && (this.dateSeek !== 'week' || !isNaN(this.week)))
    },
    computedAttach () {
      return this.attach ?? this.$refs.wrapper
    },
    prependIconComputed () {
      const icon = this.isSlidingDate ? this.$icon('i.CalendarSliding') : this.$icon('i.Calendar')
      return this.hidePrependIcon ? null : this.prependIcon || icon
    },
    computedRules () {
      return this.rules?.map(r => r(this.computedDateResolved))
    },
    computedDateResolved () {
      return this.formatDate(this.tab === 'tab2' ? this.dateResolved : this.date)
    },
    dayText () {
      if (this.dateSeek?.includes('start')) {
        return this.$t('t.FirstDay')
      } else return this.$t('t.LastDay')
    },
    firstDayOfWeek () {
      return this.moment.localeData(i18n.locale).firstDayOfWeek()
    },
    isWeekSeek () {
      return this.dateSeek === 'week' || this.dateSeek === 'weekday-backward' || this.dateSeek === 'weekday-forward'
    },
    isSlidingDate () {
      return this.date && typeof this.date === 'object'
    },
    days: {
      get () {
        return this.date?.days
      },
      set (v) {
        if (typeof v === 'string') {
          v = parseInt(v)
        }
        this.date = Object.assign({}, this.date, { days: Number(v) })
      }
    },
    months: {
      get () {
        return this.date?.months
      },
      set (v) {
        if (typeof v === 'string') {
          v = parseInt(v)
        }
        this.date = Object.assign({}, this.date, { months: Number(v) })
      }
    },
    dateSeek: {
      get () {
        return this.selectedPeriod || this.date?.seek?.type
      },
      set (v) {
        if (v) {
          this.stepText = ''
          if (v === 'days') {
            this.selectedPeriod = 'days'
            this.date = { days: this.date?.days ?? 0, months: this.date?.months ?? 0 }
          } else {
            this.selectedPeriod = null
            this.date = { days: 0, months: 0, seek: { type: v, week: 1, month: null, weekday: null } }

            if (this.isWeekSeek) {
              this.slidingWeekday = 'monday'
            }
          }
        }
      }
    },
    week: {
      get () {
        return this.date?.seek?.week
      },
      set (v) {
        if (this.date) {
          if (typeof v === 'string') {
            v = parseInt(v)
          }
          this.date.seek = Object.assign({}, this.date.seek, { week: Number(v) })
        }
      }
    },
    month: {
      get () {
        return this.date?.seek?.month
      },
      set (v) {
        if (this.date) {
          this.date.seek = Object.assign({}, this.date.seek, { month: v })
        }
      }
    },
    slidingWeekday: {
      get () {
        return this.date?.seek?.weekday
      },
      set (v) {
        if (this.date) {
          this.date.seek = Object.assign({}, this.date.seek, { weekday: v })
        }
      }
    },
    stepsList () {
      if (this.selectedPeriod === 'days') {
        return [{ key: 'today', value: this.$t('t.Today') }, { key: 'yesterday', value: this.$t('t.Yesterday') }, { key: 'aweek', value: this.$t('t.AWeek') }, { key: 'amonth', value: this.$t('t.AMonth') }, { key: 'ayear', value: this.$t('t.AYear') }]
      }
      return [{ key: 'previous', value: this.$t('t.Previous') }, { key: 'current', value: this.$t('t.CurrentDate') }, { key: 'next', value: this.$t('t.Next') }]
    }
  },
  data () {
    return {
      dateResolved: null,
      http: this.$http().debounced(),
      i18n: i18n,
      isOutOfView: false,
      modal: false,
      date: null,
      tab: 'tab-1',
      selectedPeriod: false,
      stepText: '',
      monthsList: [
        { key: 1, value: this.$t('t.January') },
        { key: 2, value: this.$t('t.February') },
        { key: 3, value: this.$t('t.March') },
        { key: 4, value: this.$t('t.April') },
        { key: 5, value: this.$t('t.May') },
        { key: 6, value: this.$t('t.June') },
        { key: 7, value: this.$t('t.July') },
        { key: 8, value: this.$t('t.August') },
        { key: 9, value: this.$t('t.September') },
        { key: 10, value: this.$t('t.October') },
        { key: 11, value: this.$t('t.November') },
        { key: 12, value: this.$t('t.December') }
      ],
      daysList: [
        { key: 'monday', value: this.$t('t.Monday') },
        { key: 'tuesday', value: this.$t('t.Tuesday') },
        { key: 'wednesday', value: this.$t('t.Wednesday') },
        { key: 'thursday', value: this.$t('t.Thursday') },
        { key: 'friday', value: this.$t('t.Friday') },
        { key: 'saturday', value: this.$t('t.Saturday') },
        { key: 'sunday', value: this.$t('t.Sunday') }
      ],
      dateSeeksList: [
        { key: 'days', value: this.$t('t.DaySpecific') },
        { key: 'month-start', value: this.$t('t.MonthStart') },
        { key: 'month-end', value: this.$t('t.MonthEnd') },
        { key: 'trimester-start', value: this.$t('t.TrimesterStart') },
        { key: 'trimester-end', value: this.$t('t.TrimesterEnd') },
        { key: 'semester-start', value: this.$t('t.SemesterStart') },
        { key: 'semester-end', value: this.$t('t.SemesterEnd') },
        { key: 'year-start', value: this.$t('t.YearStart') },
        { key: 'year-end', value: this.$t('t.YearEnd') },
        { key: 'week', value: this.$t('t.Week') },
        { key: 'weekday-backward', value: this.$t('t.WeekdayBackward') },
        { key: 'weekday-forward', value: this.$t('t.WeekdayForward') }
      ]
    }
  },
  methods: {
    validate () {
      this.$refs.text.form.validate()
    },
    blur () {
      this.$refs.text.blur()
    },
    changeStep (v) {
      if (this.dateSeek === 'days') {
        this.selectPeriod(v)
      } else {
        this.days = 0
        let step = 1
        switch (this.dateSeek) {
          case 'trimester-start':
          case 'trimester-end':
            step = 3
            break
          case 'semester-start':
          case 'semester-end':
            step = 6
            break
          case 'year-start':
          case 'year-end':
            step = 12
            break
          default:
            step = 1
            break
        }
        this.stepText = v.value
        if (v.key === 'previous') {
          this.months = -step
        } else if (v.key === 'next') {
          this.months = step
        } else if (v.key === 'current') {
          this.months = 0
        }
      }
    },
    recomputeHeight (rect) {
      if (!this.$refs.wrapper) { return }
      this.isOutOfView = (document.body.getBoundingClientRect().bottom - document.querySelector('footer').offsetHeight) < (this.$refs.wrapper.getBoundingClientRect().bottom + rect.bottom)
      this.$nextTick(this.$triggerResize)
    },
    initTab () {
      if (this.isSlidingDate) {
        this.tab = 'tab2'
        if (!this.date?.seek) {
          this.dateSeek = 'days'
        }
      } else {
        this.tab = 'tab1'
      }
    },
    cancel () {
      this.date = this.value
      this.initTab()
      this.modal = false
    },
    clearDate () {
      this.date = null
      this.saveDate()
    },
    saveDate () {
      this.modal = false
      const value = this.lodash.cloneDeep(this.date)
      if (this.month === 0) {
        value.seek.month = null
      }
      if (value?.seek?.weekday === 0) {
        value.seek.weekday = null
      }
      this.$emit('input', value)
    },
    async resolveDate (value) {
      if (value) {
        try {
          if (isNaN(value?.days) || isNaN(value?.months)) {
            this.dateResolved = null
          } else {
            if (value?.seek?.month === 0) {
              value.seek.month = null
            }
            if (value?.seek?.weekday === 0) {
              value.seek.weekday = null
            }
            const r = await this.http.post('/core/v6/cameleon/columns/resolve-date', { date: value })
            this.dateResolved = r?.data?.date
          }
        } catch (e) {
          this.dateResolved = null
          if (e !== 'Debounced') {
            throw e
          }
        }
      }
    },
    selectPeriod (value) {
      if (value) {
        switch (value.key) {
          case 'today':
            this.date = { days: 0, months: 0 }
            break
          case 'yesterday':
            this.date = { days: -1, months: 0 }
            break
          case 'aweek':
            this.date = { days: -7, months: 0 }
            break
          case 'amonth':
            this.date = { days: 0, months: -1 }
            break
          case 'ayear':
            this.date = { days: 0, months: -12 }
            break
        }
        this.stepText = value.value
      }
    },
    tab1Click () {
      this.date = null
    },
    tab2Click () {
      this.dateSeek = 'days'
      if (!this.date?.seek) {
        this.dateSeek = 'days'
      }
    }
  },
  watch: {
    date: {
      deep: true,
      handler (value) {
        this.resolveDate(value)
      }
    },
    value: {
      immediate: true,
      handler (value) {
        this.date = value ? this.lodash.cloneDeep(value) : null
      }
    }
  },
  props: {
    attach: {
      type: [String, Boolean, Object, HTMLElement],
      default: undefined
    },
    clearable: Boolean,
    disabled: Boolean,
    flat: Boolean,
    hidePrependIcon: Boolean,
    label: String,
    max: String,
    min: String,
    origin: String,
    prependIcon: String,
    readonly: Boolean,
    rules: Array,
    solo: Boolean,
    value: [String, Date, Array, Object]
  }
}
</script>
<style lang="stylus" scoped>
.picker-date
  width 100%
  max-width 100%

.resolveChip
  min-width 40%
  justify-content center
  font-weight bold

.inputnumber
  max-width 75px

.select
  min-width 200px
</style>
